import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

//components
import Layout from "../components/layout";
import Seo from "../components/seo";
import StayLoop from "../components/Home/stayloop";


const Blog = (props) => {
  const { data, pageContext } = props
  const SEOdata = data.blogPageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = data.blogPageData.edges[0].node.frontmatter?.Schema
  const Hreflang = data.blogPageData.edges[0].node.frontmatter?.Hreflang
  const posts = data.blogList.edges
  const { blogCurrentPage, blogNumPages } = pageContext
  const isFirst = blogCurrentPage === 1
  const isLast = blogCurrentPage === blogNumPages
  const prevPage = blogCurrentPage - 1 === 1 ? "" : (blogCurrentPage - 1).toString()
  const nextPage = (blogCurrentPage + 1).toString()
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "Blog"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />

      <section className="mt-60 md:mt-65 lg:mt-20 relative d-blog-main">
        <div className="container mx-auto">
          <div className="blog-main">
            <div className="blog-wrap grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-35 sm:gap-x-6-6 md:gap-y-45">
              {posts &&
                posts.map(({ node: post }, index) => (
                  (index === 0 ?
                    <article key={index} className="blog-item is-first">
                      <Link to={post.fields.slug} className="blog-item-wrap block">
                        <div className="blog-image">
                          {post.frontmatter.featured_image?.image && <GatsbyImage image={getImage(post.frontmatter.featured_image?.image)} alt={post.frontmatter.featured_image.alt} />}
                        </div>
                        <div className="blog-content">
                          <h3 className="blog-title">{post.frontmatter.title}</h3>
                          <p className="blog-category uppercase">{post.frontmatter.blog_category}</p>
                          <span className="blog-meta">{post.frontmatter.date}</span>
                        </div>
                      </Link>
                    </article>
                    :
                    <article key={index} className="blog-item">
                      <Link to={post.fields.slug} className="block">
                        <div className="blog-image">
                          {post.frontmatter.featured_image?.image && <GatsbyImage image={getImage(post.frontmatter.featured_image?.image)} alt={post.frontmatter.featured_image.alt} />}
                        </div>
                        <div className="blog-content">
                          <h3 className="blog-title">{post.frontmatter.title}</h3>
                          <p className="blog-category uppercase">{post.frontmatter.blog_category}</p>
                          <span className="blog-meta">{post.frontmatter.date}</span>
                        </div>
                      </Link>
                    </article>
                  )
                ))}
            </div>
          </div>
          {/* blogs grid */}

          {/* pagination */}
          <div className="pt-45 md:pt-84 pb-87 md:pb-1-15 blog-pagination">
            <ul className="pagination">
              {!isFirst && (
                <li className="page-next">
                  <Link className="next" to={`/blog/${prevPage}`}>
                    <svg
                      className="transform rotate-180 stroke-current text-dark-grey"
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
                    </svg>
                  </Link>
                </li>
              )}
              {blogNumPages > 1 &&
                (Array.from({ length: blogNumPages }, (_, i) => (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="page-number"
                  >
                    <Link
                      to={`/blog/${i === 0 ? "" : i + 1}`}
                      style={{
                        color:
                          i + 1 === blogCurrentPage ? "#ffffff" : "var(--text-term-secondary)",
                        background: i + 1 === blogCurrentPage ? "#00AEEF" : "",
                      }}>
                      {i + 1}
                    </Link>
                  </li>
                )))}
              {!isLast && (
                <li className="page-next">
                  <Link className="next" to={`/blog/${nextPage}`}>
                    <svg
                      className="stroke-current text-dark-grey"
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
                    </svg>
                  </Link>
                </li>
              )}
            </ul>
          </div>

        </div>
      </section>
      <StayLoop />
    </Layout>
  )
}

export default Blog;
export const pageQuery = graphql`
query BlogPageQuery($skip: Int!, $limit: Int!) {
  blogList: allMarkdownRemark(
    sort: { order: ASC, fields: [frontmatter___date] }
    filter: { frontmatter: { templateKey: { eq: "single-blog" } } }
    limit: $limit
    skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            blog_category
            templateKey
            featured_image {  
              alt            
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
            date(formatString: "DD MMM, YYYY")
          }
        }
      }
    }
    blogPageData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/pages/blog.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
          }
        }
      }
    }
  }
  `
